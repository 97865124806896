<template>
    <div class="col-lg-6 school-wrapper">
        <div class="image-wrapper h-100" :class="this.$props.background">
            <div class="informations">
                <div class="d-flex flex-column justify-content-center h-100">
                    <div class="title">
                        <h2>
                            <router-link class="categories-link" :to="this.$props.url">
                                <slot name="title"></slot>
                            </router-link>
                        </h2>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6 offset-lg-3">
                            <div class="subtitle">
                                <p>
                                    <slot name="subtitle"></slot>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['url', 'background'],
}
</script>
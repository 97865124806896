<template>
  <div class="categories">
    <Navbar/>  
    <div class="container">
        <CategorieTitle>
            <template #maintitle>
                Языковые школы
            </template>
        </CategorieTitle>

        <div class="row school-row" >

            <SchoolCard :url="'/schools/language-schools/france-langue'" :background="'image-france-langue'">
                <template #title>
                    France Langue
                </template>
                <template #subtitle>
                    Париж, Ницца, Бордо, Лион, Биарриц. Курсы французского языка для взрослых и детей. Обучение в средней школе во Франции. Детский языковой летний лагерь на море.
                </template>
            </SchoolCard>

            <SchoolCard :url="'/schools/language-schools/accord'" :background="'image-accord'">
                <template #title>
                    Accord
                </template>
                <template #subtitle>
                    школа французского языка для взрослых в 15-м округе Парижа. Детские летние программы по изучению английского в Великобритании (изучение языка + футбол с детскими тренерами ФК Paris Saint Germain)
                </template>
            </SchoolCard>
        </div>

        <div class="row school-row">
            <SchoolCard :url="'#'" :background="'image-elfe'">
                <template #title>
                    ELFE
                </template>
                <template #subtitle>
                    курсы французского языка в мини-группах в центре Парижа.
                </template>
            </SchoolCard>
            <SchoolCard :url="'#'" :background="'image-alliance'">
                <template #title>
                    Alliance Française
                </template>
                <template #subtitle>
                    самая известная языковая школа Парижа.
                </template>
            </SchoolCard>
        </div>

    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import SchoolCard from '@/components/categories/slots/SchoolCard.vue'
import CategorieTitle from '@/components/categories/slots/CategorieTitle.vue'

export default {
  name: 'LanguageSchools',
  components: {
    Navbar,
    SchoolCard,
    CategorieTitle
  }
}
</script>
